import axios from "axios";

const BASE_URL = "http://localhost:8080"; // שימו לב לשינוי BASE_URL

const SecuritiesService = axios.create({
  baseURL: BASE_URL,
});


const addSecurity = async (data) => {
  try {
    const jsonData = JSON.stringify(data);
    const response = await SecuritiesService.post('/securities', jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const status = response.status;
    return status;
  } catch (error) {
    console.error("Error fetching securities: ", error);
    throw error;
  }
};

const getAllSecurities = async () => {
  try {
    const response = await SecuritiesService.get('/securities');
    // Extract data from response.data here
    
    const securities = response.data; // Assume data is directly in "data"
    console.log(securities);
    return securities;
  } catch (error) {
    console.error("Error fetching securities:", error);
    throw error;
  }
};

// קבלת ני"ע לפי מס' ני"ע
const getSecurityByNum = async (num) => {
  try {
    const response = await SecuritiesService.get(`/securities/${num}`);
    // Check if response data is empty
    if (!response.data) {
      // If no data found, return "no data"
      return "no data";
    }
    // Extract data from response.data here
    const security = response.data; // Assuming data is directly in "data"
    console.log(security);
    return security;
  } catch (error) {
    console.error("Error fetching investment company:", error);
    throw error;
  }
};

const deleteSecurityByNum = async (num) => {
  try {
    const response = await SecuritiesService.delete(`/securities/${num}`);
    const status = response.status;
    return status;
  } catch (error) {
    console.error("Error deleting security:", error);
    throw error;
  }
};

export default {SecuritiesService,addSecurity,getAllSecurities,getSecurityByNum,deleteSecurityByNum}