import React, { useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InvestmentCompanyService from '../../services/investmentCompany/investmentComaniesService';


const useStyles = makeStyles({
  root: {
    width: '500px',
  },
  formControl: {
    width: '100%',
  },
});

const AddInvestmentCompany = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [investmentCompanyData, setInvestmentCompanyData] = useState({
    num: '',
    name: '',
    email: '',
    status: "פעיל",
    date: new Date(),
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInvestmentCompanyData({ ...investmentCompanyData, [name]: value });
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    // שליחת נתונים לסרבר
    const status= await InvestmentCompanyService.addInvestmentCompany(investmentCompanyData)
    if (status==404) {
        alert("לא ניתן להוסיף בית השקעה זה")
    }
    else if(status==200)
        alert("בית ההשקעה נוז, בהצלחה")
        navigate('/ViewCompanies')
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="מספר בית השקעה"
          name="num"
          value={investmentCompanyData.num}
          onChange={handleInputChange}
          required
        />
        <br/>
        <TextField
          label="שם בית השקעה"
          name="name"
          value={investmentCompanyData.name}
          onChange={handleInputChange}
          required
        />
        <br/>
        <FormControl className={classes.formControl}>
          <InputLabel>פעיל?</InputLabel>
          <Select
            name="status"
            value={investmentCompanyData.status}
            onChange={handleInputChange}
          >
            <MenuItem value={true}>פעיל</MenuItem>
            <MenuItem value={false}>לא פעיל</MenuItem>
          </Select>
        </FormControl>
        <br/>
        <TextField
          label="מייל "
          name="email"
          type="email"
          value={investmentCompanyData.email}
          onChange={handleInputChange}
        />
        <br/>
        <Button type="submit" variant="contained" color="primary">
          הוסף בית השקעה
        </Button>
        <Button type="reset" variant="outlined">
          אפס
        </Button>
      </form>
    </div>
  );
};

export default AddInvestmentCompany;
