import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import secuirityServices from '../../services/securites/secuirityServices';
import RequestsServiceForApproval from '../../services/requestsForApproval/requestsService';
import RequestsService from '../../services/requests/requestService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const NotInIssueModal = ({ open, handleClose,selectedInvestmentHouse }) => {
  const [securityExists, setSecurityExists] = useState(false);
  const [securityDetails, setSecurityDetails]=useState({
    num: 0,
    name:'',
    type: '',
    status: 'פעיל',
    distribution_file: null,
    hiter_aiska_file:null,
    update_date: new Date(),
    kosher_decision: 'בהמתנה',
    decision_date: new Date()
  })

  const [requestsForApprovalData,setRequestsForApprovalData]=useState({
    investment_company_number: selectedInvestmentHouse.num,
    request_number: selectedInvestmentHouse.requestNum,
    request_date: new Date(),
    status: 'בבדיקה',
    sent_date: new Date(),
    letter_url:'kkkkk'
  })

  const [requestData,setRequestData]=useState({
    request_number: selectedInvestmentHouse.requestNum,
    line_number: 1,
    security_name: securityDetails.name,
    security_type: securityDetails.type,
    in_issue: 'כן',
    kosher_decision: 'בהמתנה'
  })

  const securityTypes=['אג"ח','מניה','כתב אופציה']
  const handleNumChange = (event) => {
    setSecurityDetails({...securityDetails,num: event.target.value});
  };

  const handleSecurityTypeChange = (event) => {
    setSecurityDetails({...securityDetails,type: event.target.value});
  };

  const handleNameChange = (event) => {
    setSecurityDetails({...securityDetails,name: event.target.value});
  };

  const handleCheckSecurity = async () => {
    try {
      const response = await secuirityServices.getSecurityByNum(securityDetails.num);
      if (response === "no data") {
        setSecurityExists(false);
        // Clear security details if no data found
        setSecurityDetails({
          num: 0,
          name: '',
          type: '',
          status: 'פעיל',
          distribution_file: null,
          hiter_aiska_file: null,
          update_date: new Date(),
          kosher_decision: 'בהמתנה',
          decision_date: new Date()
        });
      } else {
        setSecurityExists(true);
        // Update security details with fetched data
        setSecurityDetails({
          num: response.num,
          name: response.name,
          type: response.type,
          status: response.status,
          distribution_file: response.distribution_file,
          hiter_aiska_file: response.hiter_aiska_file,
          update_date: response.update_date,
          kosher_decision: response.kosher_decision,
          decision_date: response.decision_date
        });
        console.log(securityDetails);
      }
    } catch (error) {
      console.error("Error fetching security:", error);
    }
  };

  const handleAddSecurity=async()=>{
    const status = await RequestsServiceForApproval.addRequestForApproval(requestsForApprovalData);
    console.log(status);
    const status2 = await RequestsService.addRequest(requestData);
    console.log(status2);
    const status3=await secuirityServices.addSecurity(securityDetails)
    console.log(status3);
    alert(`Request submitted for security ${securityDetails.name}`);
    handleClose()

  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <h2 id="parent-modal-title">הוספת ני"ע חדש לבדיקה</h2>
          <TextField
            label="מספר הנייר"
            variant="outlined"
            value={securityDetails.num}
            onChange={handleNumChange}
          />
          <Button onClick={handleCheckSecurity}>בדיקת קיום הנייר</Button>
          {securityExists ? (
            <div>
              {/* Display existing security details here */}
              <p>פרטי הנייר:</p>
              <p>מספר הנייר: {securityDetails.num}</p>
              <p>שם הנייר: {securityDetails.name}</p>
              <p>סוג הנייר: {securityDetails.type}</p>
              <p>סטטוס: {securityDetails.status}</p>
              {/* Display existing security details */}
            </div>
          ) : (
            <div>
              {/* Enter security details */}
              <TextField label="הזן שם ני\ע" variant="outlined" value={securityDetails.name} onChange={handleNameChange} required/>
              <FormControl>
                <InputLabel>בחר סוג ני"ע</InputLabel>
                <Select
                  name="securityType"
                  value={securityDetails.type}
                  onChange={handleSecurityTypeChange}
                  required
                >
                  {securityTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button onClick={handleAddSecurity}>הוסף ני"ע לאישור</Button>
              <Button onClick={handleClose}>❌</Button>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default NotInIssueModal;
