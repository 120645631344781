import React, { useState } from "react";

const HomePage = () => {
//   const [InvestmentCompanies, setInvestmentCompanies] = useState([]);

  return (
    <div>
      <h1>דף הבית</h1>
      {/* <ul>
        {InvestmentCompanies.map((investmentCompanies) => (
          <li key={investmentCompanies.oid}>{investmentCompanies.name}</li>
        ))}
      </ul> */}
    </div>
  );
};

export default HomePage;