import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";

const NavBar = () => {

  return (
    <AppBar position="static">
      <Toolbar>
        <Link to="/" style={{ textDecoration: "none" ,color: "white",}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            דף הבית
          </Typography>
        </Link>
        <Link
          to="ViewCompanies"
          style={{ textDecoration: "none",color: "white", marginLeft: "auto" }}
        >
          <Button color="inherit">צפיה בבתי השקעה</Button>
        </Link>
        <Link
          to="RequestForApproval"
          style={{ textDecoration: "none", color: "white", marginLeft: "10px" }}
        >
          <Button color="inherit">בקשה לאישור</Button>
        </Link>
        <Link
          to="AddSecurity"
          style={{ textDecoration: "none",color: "white", marginLeft: "10px" }}
        >
          <Button color="inherit"> הוספת ניר ערך </Button>
        </Link>
        <Link
          to="ViewSecurities"
          style={{ textDecoration: "none",color: "white", marginLeft: "10px" }}
        >
          <Button color="inherit"> צפיה בנירות ערך </Button>
        </Link>
        <Link
          to="addInvestmentCompany"
          style={{ textDecoration: "none",color: "white", marginLeft: "10px" }}
        >
          <Button color="inherit"> הוספת בית השקעה</Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
