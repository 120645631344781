import React from 'react';

const Loading = () => {
  return (
<div className="text-center">
  <div className="spinner-border" role="status">
    {/* <span class="sr-only">Loading...</span> */}
  </div>
</div>

  );
};

export default Loading;