import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import SecuritiesService from "../../services/securites/secuirityServices"

const useStyles = makeStyles({
  root: {
    width: '500px',
  },
  formControl: {
    width: '100%',
  },
});

const AddSecurity = () => {
  const classes = useStyles();
  const [securityData, setSecurityData] = useState({
    num: '',
    num: '',
    name: '',
    type: '',
    status: '',
    isIssuing: null,
    updateDate: new Date(),
    isKosher: '',
    kosherDecisionDate: new Date(),
  });

  const handleInputChange = (event) => { 
    const { name, value } = event.target;
    setSecurityData({ ...securityData, [name]: value });
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    // שליחת נתונים לסרבר
    const status = await SecuritiesService.addSecurity(securityData);
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="מספר ניע"
          name="num"
          value={securityData.num}
          onChange={handleInputChange}
          required
        />
        <FormControl className={classes.formControl}>
          <InputLabel>בהנפקה?</InputLabel>
          <Select
            name="isIssuing"
            value={securityData.isIssuing}
            onChange={handleInputChange}
          >
            <MenuItem value={true}>כן</MenuItem>
            <MenuItem value={false}>לא</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="שם ניע"
          name="name"
          value={securityData.name}
          onChange={handleInputChange}
          required
        />
        <FormControl className={classes.formControl}>
          <InputLabel>סוג ני"ע</InputLabel>
          <Select
            name="type"
            value={securityData.type}
            onChange={handleInputChange}
            required
          >
            <MenuItem value="">בחר סוג ני"ע</MenuItem>
            <MenuItem value="stock">מניה</MenuItem>
            <MenuItem value="bond">אג"ח</MenuItem>
            <MenuItem value="bond">כתב אופציה</MenuItem>
            <MenuItem value="option">אופציה</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel>סטאטוס ני"ע</InputLabel>
          <Select
            name="status"
            value={securityData.status}
            onChange={handleInputChange}
            required
          >
            <MenuItem value="">בחר סטאטוס</MenuItem>
            <MenuItem value="active">פעיל</MenuItem>
            <MenuItem value="closed">סגור</MenuItem>
            <MenuItem value="pending">בהמתנה</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="תאריך עדכון"
          name="updateDate"
          type="date"
          value={securityData.updateDate}
          onChange={handleInputChange}
          required
        />
        <FormControl className={classes.formControl}>
          <InputLabel>החלטת כשרות</InputLabel>
          <Select
            name="isKosher"
            value={securityData.isKosher}
            onChange={handleInputChange}
          >
            <MenuItem value="approved">מאושר</MenuItem>
            <MenuItem value="review">בבדיקה</MenuItem>
            <MenuItem value="pending">בהמתנה</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="תאריך החלטה"
          name="kosherDecisionDate"
          type="date"
          value={securityData.kosherDecisionDate}
          onChange={handleInputChange}
        />
        <Button type="submit" variant="contained" color="primary">
          הוסף ני"ע
        </Button>
        <Button type="reset" variant="outlined">
          אפס
        </Button>
      </form>
    </div>
  );
};

export default AddSecurity;
