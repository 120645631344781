import React, { useState, useEffect } from "react";
import InvestmentCompanyService from '../../services/investmentCompany/investmentComaniesService'
import Loading from "../loading";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";

const ViewCompanies = () => {
  const [investmentCompanies, setInvestmentCompanies] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const data = await InvestmentCompanyService.getAllInvestmentCompanies();
        setInvestmentCompanies(data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanies();
  }, []);

  const handleDelete = async (companyId) => {
    try {
      // Call your service to delete the investment company
      await InvestmentCompanyService.deleteInvestmentCompany(companyId);
      // Filter out the deleted company from the state
      setInvestmentCompanies(investmentCompanies.filter(company => company.num !== companyId));
      console.log("delete");
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <p>Error fetching investment companies: {error.message}</p>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>תאריך עדכון</TableCell>
                <TableCell>סטאטוס פעיל</TableCell>
                <TableCell>כתובת מייל</TableCell>
                <TableCell>שם בית השקעה</TableCell>
                <TableCell>מספר בית השקעה</TableCell>
                <TableCell>מחיקה</TableCell> {/* Add header for actions */}
              </TableRow>
            </TableHead>
            <TableBody>
              {investmentCompanies &&
                investmentCompanies.map((company, i) => (
                  <TableRow key={i}>
                    <TableCell>{company.date}</TableCell>
                    <TableCell>{company.status}</TableCell>
                    <TableCell>{company.email}</TableCell>
                    <TableCell>{company.name}</TableCell>
                    <TableCell>{company.num}</TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleDelete(company.num)}>🗑️</Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ViewCompanies;
