import axios from "axios";

const BASE_URL = "http://localhost:8080"; // שימו לב לשינוי BASE_URL

const RequestsService = axios.create({
  baseURL: BASE_URL,
});

// הוספת בקשה 
const addRequest = async (data) => {
  try {
    const jsonData = JSON.stringify(data);
    const response = await RequestsService.post('/requests', jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log(response);
    const status = response.status;
    return status;
  } catch (error) {
    console.error("Error adding requests: ", error);
    throw error;
  }
};

export default {RequestsService,addRequest}