import React, { useState, useEffect } from 'react';
import InvestmentCompanyService from '../../services/investmentCompany/investmentComaniesService';
import RequestsServiceForApproval from '../../services/requestsForApproval/requestsService';
import './RequestForApproval.css';
import DraftsIcon from '@mui/icons-material/Drafts';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListSubheader from '@mui/material/ListSubheader';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { TextField, Autocomplete, Button, Collapse, FormControl, InputLabel, MenuItem, Modal, Select, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InIssueModal from '../models/InIssue';
import NotInIssueModal from '../models/NotInIssue';

const useStyles = makeStyles({
  root: {
    width: '50%',
    margin: '2%',
    border: '2px solid #ccc',
    padding: '4%',
    position: 'relative',
    left: '22%'
  },
  p: {
    marginLeft: '70%',
    background: 'gray',
    color: 'white'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  securityDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
});

const RequestForApproval = () => {
  const classes = useStyles();
  const [investmentHouses, setInvestmentHouses] = useState([]);
  const [selectedInvestmentHouse, setSelectedInvestmentHouse] = useState(null);
  const [requestNum, setRequestNum] = useState(0);
  const [isInOfferingModalOpen, setIsInOfferingModalOpen] = useState(false);
  const [isInSecurityDetailsModalOpen, setIsInSecurityDetailsModalOpen] = useState(false);
  const [isInIssueSecurity, setIsInIssueSecurity] = useState(false);
  const [isInIssueSecurityModal, setIsInIssueSecurityModal] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const fetchInvestmentHouses = async () => {
      const data = await InvestmentCompanyService.getAllInvestmentCompanies();
      setInvestmentHouses(data);
    };
    fetchInvestmentHouses();

    const fetchRequestNum = async () => {
      const reqNum = await RequestsServiceForApproval.getLastRequestID();
      setRequestNum(reqNum);
    };
    fetchRequestNum();
  }, []);

  const showInput = () => {
    setShowEmailInput(!showEmailInput);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const addEmailToTheCustomer = async () => {
    const date = {
      num: selectedInvestmentHouse.num,
      email: email
    };
    const status = await InvestmentCompanyService.addEmailToInvestmentCompany(date);
    if (status === 200) {
      setInvestmentHouses(prevState => {
        const updatedInvestmentHouses = prevState.map(item => {
          if (item.num === selectedInvestmentHouse.num) {
            return { ...item, email: [...item.email, email] };
          }
          return item;
        });
        return updatedInvestmentHouses;
      });
      alert("The email was added successfully: " + email);
    }
  };

  const handleInputChange = (event, value) => {
    setSelectedInvestmentHouse(value);
    setIsInIssueSecurity(value);
  };

  const handleAddSecurityClick = () => {
    setIsInOfferingModalOpen(!isInOfferingModalOpen);
    setIsInSecurityDetailsModalOpen(!isInSecurityDetailsModalOpen);
  };

  const handleInputInIssueChange = (event) => {
    let value = event.target.value;
    setIsInIssueSecurityModal(true);
    setIsInIssueSecurity(value);
  };

  const handleClick = () => {
    setOpen(!open);
  };


  return (
    <div className={classes.root}>
      <h1 className='requestH1'>בקשה לאישור</h1>
      <p className={classes.p}>{requestNum} -מספר בקשה לאשור </p>
      <br />
      <Autocomplete
        className='autocomplete'
        options={investmentHouses}
        getOptionLabel={(option) => option.name}
        value={selectedInvestmentHouse}
        onChange={handleInputChange}
        renderInput={(params) => (
          <TextField {...params} label="חפש בית השקעות" variant="outlined" required/>
        )}
      />
      {selectedInvestmentHouse && (
        <>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Email for: {selectedInvestmentHouse && selectedInvestmentHouse.name}
              </ListSubheader>
            }
          >
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <ForwardToInboxIcon />
              </ListItemIcon>
              <ListItemText primary="Email" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {selectedInvestmentHouse.email && Array.isArray(selectedInvestmentHouse.email) ?
                  selectedInvestmentHouse.email.map((item, index) => (
                    <ListItem key={index} sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  )) : <ListItem sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon>
                      <ListItemText primary={selectedInvestmentHouse.email} />
                    </ListItem>
                }
              </List>
            </Collapse>
          </List>
          <br />
          <Button className='addEmailButton' variant="outlined" onClick={showInput}>לחץ כאן לעדכון כתובת אימייל חדשה </Button>
          <br />
          {showEmailInput &&
            <div className='showEmail'>
              <TextField
                type='email'
                label="הוסף מייל למאגר"
                value={email}
                onChange={handleEmailChange}
                variant="outlined"
              />
              <Button variant="outlined" onClick={addEmailToTheCustomer}>
                הוסף מייל
              </Button>
            </div>
          }
          <Button className='secButton' variant="outlined" onClick={handleAddSecurityClick}>הוספת ני"ע</Button>
          <br/>
          {isInSecurityDetailsModalOpen &&
            <FormControl>
                <InputLabel>בהנפקה?</InputLabel>
                <Select
                  name="isIssuing"
                  value={isInIssueSecurity}
                  onChange={handleInputInIssueChange}
                >
                  <MenuItem value={true}>כן</MenuItem>
                  <MenuItem value={false}>לא</MenuItem>
                </Select>
              </FormControl>
          }
  
          {isInIssueSecurityModal && isInIssueSecurity ? (
            <InIssueModal selectedInvestmentHouse={selectedInvestmentHouse} requestNum={requestNum} />
          ) : (
            selectedInvestmentHouse && <NotInIssueModal selectedInvestmentHouse={selectedInvestmentHouse} open={isInIssueSecurityModal} handleClose={() => setIsInIssueSecurityModal(false)} />
          )}
        </>
      )}
    </div>
  );
  
  
};

export default RequestForApproval;