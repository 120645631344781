import React, { useState, useEffect } from "react";
import SecuritiesService from "../../services/securites/secuirityServices";
import Loading from "../loading";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem } from "@mui/material";

const ViewSecurities = () => {
  const [securities, setSecurities] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSecurities = async () => {
      try {
        const data = await SecuritiesService.getAllSecurities();
        setSecurities(data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSecurities();
  }, []);

  const handleDelete = async (num) => {
    try {
      await SecuritiesService.deleteSecurityByNum(num);
      setSecurities(securities.filter(security => security.num !== num));
    } catch (error) {
      setError(error);
    }
  };

  const exchange = (data) => {
    return data.map((i) => ({
      ...i,
      in_issue: i.in_issue ? "כן" : "לא",
    }));
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <p>Error fetching securities: {error.message}</p>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>מס' ני"ע</TableCell>
                <TableCell>בהנפקה</TableCell>
                <TableCell>שם ני"ע</TableCell>
                <TableCell> סוג ני"ע</TableCell>
                <TableCell> סטאטוס ני"ע</TableCell>
                <TableCell>תאריך עדכון</TableCell>
                <TableCell>החלטת כשרות</TableCell>
                <TableCell>תאריך החלטה</TableCell>
                <TableCell>פעולות</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {securities.map((security, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {security.num === '999999' ? (
                      <Select
                        value={security.name}
                        onChange={(event) => {
                          const selectedSecurity = event.target.value;
                          const updatedSecurities = securities.map(s => {
                            if (s.name === selectedSecurity) {
                              return { ...s, num: '999999' };
                            }
                            return s;
                          });
                          setSecurities(updatedSecurities);
                        }}
                      >
                        {securities.map((item, index) => (
                          <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    ) : (
                      security.num
                    )}
                  </TableCell>
                  <TableCell>{security.in_issue}</TableCell>
                  <TableCell>{security.name}</TableCell>
                  <TableCell>{security.type}</TableCell>
                  <TableCell>{security.status}</TableCell>
                  <TableCell>{security.update_date}</TableCell>
                  <TableCell>{security.kosher_decision}</TableCell>
                  <TableCell>{security.decision_date}</TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={() => handleDelete(security.num)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ViewSecurities;
