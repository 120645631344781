import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField, Autocomplete } from '@mui/material';
import {Button,Select,InputLabel,FormControl,Input,MenuItem} from '@mui/material';
import SecuritiesService from '../../services/securites/secuirityServices';
import RequestsServiceForApproval from '../../services/requestsForApproval/requestsService';
import RequestsService from '../../services/requests/requestService';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const InIssueModal = (selectedInvestmentHouse) => {
  const [open, setOpen] = useState(true);
  const [securities, setSecurities] = useState([]);
  const [selectedSecurity, setselectedSecurity] = useState(null);
  const securityTypes=['אג"ח','מניה','כתב אופציה']
  const [securityDetails, setSecurityDetails]=useState({
    num: 999999,
    name:'',
    type: '',
    status: 'פעיל',
    distribution_file: null,
    hiter_aiska_file:null,
    update_date: new Date(),
    kosher_decision: 'בהמתנה',
    decision_date: new Date()
  })

  const [requestsForApprovalData,setRequestsForApprovalData]=useState({
    investment_company_number: selectedInvestmentHouse.num,
    request_number: selectedInvestmentHouse.requestNum,
    request_date: new Date(),
    status: 'בבדיקה',
    sent_date: new Date(),
    letter_url:'kkkkk'
  })

  const [requestData,setRequestData]=useState({
    request_number: selectedInvestmentHouse.requestNum,
    line_number: 1,
    security_name: securityDetails.name,
    security_type: securityDetails.type,
    in_issue: 'כן',
    kosher_decision: 'בהמתנה'
  })

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event, value) => {
    setselectedSecurity(value || null);
  };

  const submitNewSecurity = async() => {
    // Logic for submitting the request for approval
    const status = await RequestsServiceForApproval.addRequestForApproval(requestsForApprovalData);
    console.log(status);
    const status2 = await RequestsService.addRequest(requestData);
    console.log(status2);
    const status3=await SecuritiesService.addSecurity(securityDetails)
    console.log(status3);
    alert(`Request submitted for security ${securityDetails.name} of type ${selectedSecurity.type}`);
    handleClose()
  };

  const submitExitingSecurity = async() => {
    // Logic for submitting the request for approval
    const status = await RequestsServiceForApproval.addRequestForApproval(requestsForApprovalData);
    console.log(status);
    const status2 = await RequestsService.addRequest(requestData);
    console.log(status2);
    alert(`Request submitted for security ${selectedSecurity.name} of type ${selectedSecurity.type}`);
    handleClose()
  };

  useEffect(() => {
    const fetchSecurities = async () => {
      try {
        const data = await SecuritiesService.getAllSecurities();
        setSecurities(data);
      } catch (error) {
        console.error('Error fetching data from server:', error);
      }
    };

    fetchSecurities();
  }, []); 

  const handleSecurityTypeChange = (event) => {
    setSecurityDetails({...securityDetails,type: event.target.value});
  };

  const handleDistributionFileChange = (event) => {
    setSecurityDetails({...securityDetails,distribution_file: event.target.files[0]});
  };

  const handleHiterAiskaFileChange = (event) => {
    setSecurityDetails({...securityDetails,hiter_aiska_file: event.target.files[0]});
  };

  const handleNameChange = (event) => {
    setSecurityDetails({...securityDetails,name: event.target.value});
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h2 id="parent-modal-title">הוספת ני"ע בהנפקה</h2>
        <p id="parent-modal-description">חיפוש ני"ע קיים לפי שם</p>
        <div>
          {securities && securities.length > 0 ? (
            <Autocomplete
              className='autocomplete'
              options={securities}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              value={selectedSecurity}
              onChange={handleInputChange}
              renderInput={(params) => (
                <TextField {...params} label="חפש ניר ערך" variant="outlined" />
              )}
            />
          ) : (
            <p>Loading...</p>
          )}
          {selectedSecurity ? (
            <div>
              {/* <p>{`סוג ני"ע: ${selectedSecurity.type}`}</p> */}
              <FormControl>
                <InputLabel>בחר סוג ני"ע</InputLabel>
                <Select
                  name="securityType"
                  value={securityDetails.type}
                  onChange={handleSecurityTypeChange}
                  required
                >
                  {securityTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button variant="outlined" onClick={submitExitingSecurity}>
                { 'הוסף ני"ע ל' + selectedInvestmentHouse.selectedInvestmentHouse.name}
              </Button>
            </div>
          ) : (
            <div>
              <TextField label="הזן שם ני\ע" variant="outlined" value={securityDetails.name} onChange={handleNameChange} required/>
              <FormControl>
                <InputLabel>בחר סוג ני"ע</InputLabel>
                <Select
                  name="securityType"
                  value={securityDetails.type}
                  onChange={handleSecurityTypeChange}
                  required
                >
                  {securityTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <input type="file" onChange={handleDistributionFileChange} />
              <input type="file" onChange={handleHiterAiskaFileChange} />
              <Button variant="outlined" onClick={submitNewSecurity}>
                שלח בקשה לאישור
              </Button>
            </div>
          )}
          <Button onClick={handleClose}>❌</Button>
        </div>
      </Box>
    </Modal>
  );
};

export default InIssueModal;
