import axios from "axios";

const BASE_URL = "http://localhost:8080"; // שימו לב לשינוי BASE_URL

const RequestsServiceForApproval = axios.create({
  baseURL: BASE_URL,
});

const getLastRequestID = async () => {
    try {
      const response = await RequestsServiceForApproval.get('/requestsForApproval');
      // Extract data from response.data here
      const ExtractData = response.data; // Assume data is directly in "data"
      console.log(ExtractData);
      return ExtractData+1;
    } catch (error) {
      console.error("Error fetching investment company:", error);
      throw error;
    }
};
// הוספת בקשה לאישור
const addRequestForApproval = async (data) => {
  try {
    const jsonData = JSON.stringify(data);
    const response = await RequestsServiceForApproval.post('/requestsForApproval', jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log(response);
    const status = response.status;
    return status;
  } 
    catch (error) {
    console.error("Error adding requestsForApproval: ", error);
    throw error;
  }
};

export default {RequestsServiceForApproval,getLastRequestID,addRequestForApproval}