import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import  HomePage  from "./components/homePage";
import ViewCompanies from "./components/investmentComany/viewComanies";
import NavBar from "./components/navBar";
import RequestForApproval from "./components/requests/RequestForApproval";
import AddSecurity from "./components/securities/addSecurity"
import ViewSecurities from "./components/securities/viewSecurites";
import AddInvestmentCompany from "./components/investmentComany/addInvestmentCompany";

const App = () => {
  const routes = [
    { path: "/", component: <HomePage/> },
    { path: "/ViewCompanies", component: <ViewCompanies/> },
    { path: "/RequestForApproval", component: <RequestForApproval/> },
    { path: "/AddSecurity", component: <AddSecurity/> },
    { path: "/ViewSecurities", component: <ViewSecurities/> },
    { path: "/addInvestmentCompany", component: <AddInvestmentCompany/> },
  ];

  return (
    <Router>
      <NavBar/>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.component} />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
