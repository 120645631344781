import axios from "axios";

const BASE_URL = "https://mkapha.com/.netlify/functions/server";

// axios.defaults.baseURL = 'https://mkapha.com';

const InvestmentCompanyService = axios.create({
  baseURL: BASE_URL,
});
 
// קבלת כל בתי ההשקעות
  const getAllInvestmentCompanies = async () => {
      try {
        const response = await InvestmentCompanyService.get("/investmentCompany");
        // Extract data from response.data here
        const investmentCompanies = response.data; // Assume data is directly in "data"
        return investmentCompanies;
      } catch (error) {
        console.error("Error fetching investment companies:", error);
        throw error;
      }
  };
  // קבלת בית השקעה לפי מספר בית השקעה
  const getInvestmentCompany = async (num) => {
    try {
      const response = await InvestmentCompanyService.get(`/investmentCompany/${num}`);
      // Extract data from response.data here
      const investmentCompany = response.data; // Assume data is directly in "data"
      console.log(investmentCompany);
      return investmentCompany;
    } catch (error) {
      console.error("Error fetching investment company:", error);
      throw error;
    }
  };
    // הוספת בית השקעה
  const addInvestmentCompany = async (data) => {
    try {
      const jsonData = JSON.stringify(data);
      const response = await InvestmentCompanyService.post('/investmentCompany', jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response);
      const status = response.status;
      return status;
    } catch (error) {
      console.error("Error fetching securities: ", error);
      throw error;
    }
  };

  const addEmailToInvestmentCompany = async (data) => {
    try {
      const num=data.num
      const email=data.email
      console.log("לפני שליחה לסרבר");
      const response = await InvestmentCompanyService.put(`/investmentCompany/${num}/${email}`);
      const status = response.status;
      console.log("אחרי שליחה לסרבר");

      return status;
    } catch (error) {
      console.error("Error fetching investment company:", error);
      throw error;
    }
  };

  const deleteInvestmentCompany = async (num) => {
    try {
      const response = await InvestmentCompanyService.delete(`/investmentCompany/${num}`);
      const status = response.status;
      return status;
    } catch (error) {
      console.error("Error deleting investment company:", error);
      throw error;
    }
  };

  


export default {InvestmentCompanyService,getAllInvestmentCompanies,getInvestmentCompany,addInvestmentCompany,addEmailToInvestmentCompany,deleteInvestmentCompany};
